/**
 * Main app
 *
 */

"use strict";

import { Popover, Modal } from 'bootstrap';
import Splide from '@splidejs/splide';

window.Popover = Popover;

class App {
  constructor() {
    const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
    const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new Popover(popoverTriggerEl))

    this.init_custom_events();
    this.init_address();
    this.init_quote_estimate();
    this.init_sliders();
  }

  /**
   * Init manual address form
   *
   */
  init_address()
  {
    const address_open   = document.querySelector('.page-site-form-v2-address-open');
    const address_manual = document.querySelector('.page-site-form-v2-address-manual');
    const address_auto   = document.querySelector('input[name=address]');

    address_open?.addEventListener('click', (e) => {
      address_manual?.classList.remove('hidden');
      address_auto?.setAttribute('disabled', 'disabled');
    });
  }

  /**
   * Init custom events
   *
   */
  init_custom_events()
  {
    window.dataLayer = window.dataLayer || [];

    const progress_bar = document.querySelector('.progress-bar');
    const back_button  = document.querySelector('button.back');
    const modal        = document.getElementById('savings-modal');

    // Back button
    document.addEventListener('form:back', (e) => {
      if ( back_button )
      {
        if ( ! back_button.getAttribute('data-previous-step') )
        {
          back_button.classList.add('opacity-0');
        }
        else
        {
          back_button.classList.remove('opacity-0');
        }
      }

      if ( e.detail?.previousStep == 'debt-step' ) {
        progress_bar?.setAttribute('style', 'width: 10%');
      }

      if ( e.detail?.previousStep == 'contact-step' ) {
        progress_bar?.setAttribute('style', 'width: 50%');
      }
    });

    // Toggle the form progress bar
    document.addEventListener('form:stepChange', ( e ) => {
      back_button?.classList.remove('opacity-0');

      if ( e.detail?.form_details.nextStep == 'contact-step' ) {
        progress_bar?.setAttribute('style', 'width: 50%');
      }

      if ( e.detail?.form_details.nextStep == 'address-step' ) {
        progress_bar?.setAttribute('style', 'width: 80%');
      }
    });

    // Show loading modal on final step
    document.addEventListener('form:beforeSubmit', (e) => {
      console.log('before submit');

      if ( e && e.detail.form_details && e.detail.form_details.isCompleted && modal )
      {
        console.log('show modal');

        const loading_modal = new Modal(document.getElementById('savings-modal'));
        loading_modal.show();
      }
    });

    // Fire dataLayer events
    document.addEventListener('form:afterSubmit', (e) => {
      if ( e && e.detail.form_details && e.detail.response )
      {
        const form_details = e.detail.form_details;
        const response     = e.detail.response;

        if ( ! response.status || response.status == 200 )
        {
          // Step 1 - Mailer Code
          if ( form_details.leadAction == 'offer_code' )
          {
            window.dataLayer.push({
              event: "mailer_code",
              lead_id: response.uuid,
            });
          }

          // Step 2 - Debt Amount
          if ( form_details.currentStep == 'debt-step')
          {
            window.dataLayer.push({
              event: "leadDebtAmount",
              lead_id: response.uuid,
            });
          }

          if ( form_details.currentStep == 'contact-step')
          {
            window.dataLayer.push({
              event: "leadInfo",
              lead_id: response.uuid,
            });
          }

          if ( form_details.currentStep == 'zip-step')
          {
            window.dataLayer.push({
              event: "zipcode",
              lead_id: response.uuid,
            });
          }

          if ( form_details.isCompleted )
          {
            const debtCreditReport = ( response.debtCreditReport ) ? parseFloat(response.debtCreditReport) : 0.00;

            window.dataLayer.push({
              event: "leadGenerated",
              lead_id: response.uuid,
              firstName: response.firstName,
              lastName: response.lastName,
              phoneNumber: response.phone,
              leadStatus: response.quality,
              email: response.email,
              city: response.city,
              zip: response.zip,
              debt_amount: response.debt,
              credit_pull: debtCreditReport
            });
          }
        }
      }
    });
  }

  /**
   * Initialize quote estimate TY page
   *
   * @see https://www.bankrate.com/finance/credit-cards/minimum-payment-calculator/
   */
  init_quote_estimate()
  {
    function calculatePaymentAndInterest( balance, interestRate )
    {
      const monthlyInterestRate = interestRate / 100 / 12;
      let totalInterest         = 0;
      let months                = 0;
      let originalBalance       = balance;
  
      while ( balance > 0 )
      {
        const interest      = balance * monthlyInterestRate;
              totalInterest += interest;
        
         // Minimum payment is interest + 1% of balance
        let minimumPayment = ( balance * 0.01 ) + interest;

        if (minimumPayment <= 15) minimumPayment = 15;

        // Decrease balance by subtracting payment
        balance += interest - minimumPayment;

        months++;
      }
  
      const years = ( months >= 12 ) ? Math.floor(months / 12) : null;

      return {
          totalMinimumPayment: (balance + totalInterest).toFixed(2), // Last payment includes balance + total interest
          totalInterest: totalInterest.toFixed(2),
          months: months,
          years: years,
          total: totalInterest + originalBalance
      };
    }
  
    const quote_page   = document.querySelector('.quote-estimate');
    const lead         = sessionStorage.getItem('lead');
    const quote_values = ( lead !== null ) ? JSON.parse(lead).quote[0] : null;

    if ( ! quote_page || ! quote_values ) return;

    const firstName = quote_page.querySelectorAll('.quote-firstName');

    if ( firstName.length )
    {
      const name = JSON.parse(lead).firstName;
      
      firstName[0].innerHTML = name.charAt(0).toUpperCase() + name.slice(1)
    }

    // Custom calculations
    const paymentWithoutHelp = quote_page.querySelectorAll('.quote-paymentWithout');
    const timeframe          = quote_page.querySelectorAll('.quote-timeframe');
    const savingsElement     = quote_page.querySelectorAll('.quote-savings');
    const averageApr         = 24;
    const result             = calculatePaymentAndInterest( quote_values.amount, averageApr);
    const savings            = result.total - quote_values.totalRepayment;

    paymentWithoutHelp?.forEach( (item) => item.innerHTML = '$' + Math.floor(result.total).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") );
    savingsElement?.forEach( (item) => item.innerHTML = '$' + Math.floor(savings).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") );
    
    if ( result.years )
    {
      let string = ( result.years == 1 ) ? ' year' : ' years';
      
      timeframe?.forEach( (item) => item.innerHTML = result.years + ' ' + string );
    }
    else
    {
      let string = ( result.months == 1 ) ? ' month' : ' months';

      timeframe?.forEach( (item) => item.innerHTML = result.months + ' ' + string );
    }

    // Add quote values to page
    for ( const key in quote_values )
    {
      if ( key != 'savings' )
      {
        let el = quote_page.querySelectorAll(`.quote-${key}`);

        el?.forEach( (item) => {
          if ( key == 'months' )
          {
            item.innerHTML = quote_values[key] + ' months';
          }
          else
          {
            item.innerHTML = '$' + Math.floor(quote_values[key]).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
        });
      }
    }
  }

  /**
   * Init sliders
   *
   */
  init_sliders()
  {
    const sliders = document.querySelectorAll('section.splide');

    if ( ! sliders) return;

    sliders.forEach(( slider ) => {
      var splide = new Splide( slider, {
        type   : 'loop',
        arrows: false,
        perPage: '3',
        width: '100%',
        gap: '1rem',
        pagination: true,
        breakpoints: {
          1024: {
            perPage: '2',
          },
          768: {
            perPage: '1',
            padding: {left: 0, right: 50},
          },
        }
      } );

      splide.mount();
    });
  }
}

const app = new App();
